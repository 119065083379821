import React from 'react';
import PropTypes from 'prop-types';

const wrapPageElement = ({ element, props }) => {
  // eslint-disable-next-line no-console
  console.log('wrapPageElement', { element, props });
  return (
    <React.Fragment>
      {element}
    </React.Fragment>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.shape({}).isRequired,
};

export default wrapPageElement;
