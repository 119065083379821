import PropTypes from 'prop-types';

export const sheetByPathname = new Map();

const wrapRootElement = ({ element, pathname, ...rest }) => {
  console.info('wrapRootElement', { element, pathname, ...rest });
  return element;
};

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default wrapRootElement;
